import React from "react"
import { graphql } from "gatsby"
import Head from "../components/head"
import Layout from "../components/layout"
import Image from "../components/image"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Intro from "../components/intro"

export const query = graphql`
  query ($slug: String!) {
    markdownRemark (fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        layout
        image
        order
      }
      html
    }
    allFile(
      filter: {extension: {eq: "md"}, childMarkdownRemark: {frontmatter: {layout: {eq: "services"}}}}
    ) {
      edges {
        previous {
          childMarkdownRemark {
            fields {
              slug
            }
            frontmatter {
              title
              image
              order
            }
          }
        }
        next {
          childMarkdownRemark {
            fields {
              slug
            }
            frontmatter {
              title
              image
              order
            }
          }
        }
      }
    }
  }
`

export default function Services(props) {
  const data = props.data.markdownRemark.frontmatter
  // const previous = props.data.allFile.edges[data.order - 1].previous.childMarkdownRemark
  // const next = props.data.allFile.edges[data.order - 1].next.childMarkdownRemark

  return (
    <Layout>
      <Head title={data.title} robots="index, follow" />
      <div className="my-16 sm:my-24 lg:my-32">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-4xl mx-auto mb-8 md:mb-12 lg:mb-16 text-center">
            <AniLink
              paintDrip
              to={data.layout === "services" ? "/leistungen" : "/leistungen#igel"}
              duration={1}
              hex="F5DCAD"
              className="block text-base text-petrol font-semibold hover:underline mb-4"
            >{data.layout === "services" ? "Leistungen" : "IGeL-Leistungen"}</AniLink>
            <h1 className="block text-4xl !leading-[1.1] font-serif text-petrol sm:text-5xl lg:text-6xl break-words hyphens-auto">{data.title}</h1>
          </div>
          <Image
            filename={data.image.split('/assets/').pop()}
            alt={data.image.split('/assets/').pop().replace(new RegExp('.png|.jpg|.jpeg|.gif'), '')}
            objectFit="cover"
            className="max-w-screen-xl mx-auto"
            imgClassName="max-h-[100vh] w-auto"
          />
          <div className="prose prose-petrol prose-md md:prose-lg text-petrol prose-headings:text-petrol prose-h1:font-serif prose-h1:font-normal prose-h2:font-serif prose-h2:font-normal prose-h3:font-serif prose-h3:font-normal prose-a:text-petrol prose-blockquote:text-petrol prose-strong:text-petrol mx-auto max-w-4xl mt-8 md:mt-12 lg:mt-16" dangerouslySetInnerHTML={{__html: props.data.markdownRemark.html}}>
          </div>
        </div>
      </div>
      <div className="bg-green-lighter pb-16 sm:pb-24 lg:pb-32 overflow-hidden">
        <Intro title={data.layout === "services" ? "Weitere Leistungen" : "Weitere IGeL-Leistungen"} titleHeading="h6" />
        <div className="text-center">
          <AniLink
            paintDrip
            to={data.layout === "services" ? "/leistungen" : "/leistungen#igel"}
            duration={1}
            hex="F5DCAD"
            className="mt-10 inline-flex items-center justify-center px-8 py-3 md:px-10 md:py-4 border border-transparent text-base font-semibold rounded-full text-white bg-petrol hover:bg-green whitespace-nowrap transition-colors"
          >Zur Leistungsübersicht</AniLink>
        </div>
        {/* <div className="mt-8 mb-16 sm:mt-12 sm:mb-24 lg:mt-16 lg:mb-32">
          <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
            <div className="grid sm:grid-cols-2 gap-x-8 gap-y-12">
              {data.order > 1 && <AniLink
                paintDrip
                to={`/leistungen/${props.data.allFile.edges[data.order - 1].previous.childMarkdownRemark.fields.slug}`}
                duration={1}
                hex="F5DCAD"
                key={props.data.allFile.edges[data.order - 1].previous.childMarkdownRemark.frontmatter.title}
              >
                <Image
                  filename={props.data.allFile.edges[data.order - 1].previous.childMarkdownRemark.frontmatter.image.split('/assets/').pop()}
                  alt={props.data.allFile.edges[data.order - 1].previous.childMarkdownRemark.frontmatter.image.split('/assets/').pop().replace(new RegExp('.png|.jpg|.jpeg|.gif'), '')}
                  className="aspect-w-3 aspect-h-2 overflow-hidden mb-6"
                />
                <h3 className="text-xl font-serif text-petrol sm:text-2xl lg:text-3xl">
                  {props.data.allFile.edges[data.order - 1].previous.childMarkdownRemark.frontmatter.title} {props.data.allFile.edges[data.order - 1].previous.childMarkdownRemark.frontmatter.order}
                </h3>
              </AniLink>}
              {next && <AniLink
                paintDrip
                to={`/leistungen/${next.fields.slug}`}
                duration={1}
                hex="F5DCAD"
                key={next.frontmatter.title}
              >
                <Image
                  filename={next.frontmatter.image.split('/assets/').pop()}
                  alt={next.frontmatter.image.split('/assets/').pop().replace(new RegExp('.png|.jpg|.jpeg|.gif'), '')}
                  className="aspect-w-3 aspect-h-2 overflow-hidden mb-6"
                />
                <h3 className="text-xl font-serif text-petrol sm:text-2xl lg:text-3xl">
                  {next.frontmatter.title} {next.frontmatter.order}
                </h3>
              </AniLink>}
            </div>
          </div>
        </div> */}
      </div>
    </Layout>
  )
}
